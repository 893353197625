import React from 'react'

const Footer = () => {
	return (
		<footer className="footer-container">
			<div className="footer-content">
				<p class="resume">
					<a href="https://rebrand.ly/fdk7igt" target="_blank" rel="noopener noreferrer">Download resume</a>
				</p>
			</div>
    	</footer>
	);
};

export default Footer;
